.parentDiv {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 90%;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top:1%;
    margin-bottom: 1%;


}

.headerDiv {
    text-align: center;
    margin-bottom: 20px;
}

.selectorsDiv {
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Arial, sans-serif;
    margin-inline: 10px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.actionBorder {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: sticky;
    z-index: 9999;
    background-color:rgb(240, 240, 240);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    width: 100%;
    top: 0;

    border: 1px solid #ccc;
    border-radius: 10px;

}

.actionGroup {
    margin-top: 1.5%;
    align-items: center;
    width: 100%;
}

.actionsButtonDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-top: 1%;
    margin-bottom: 0%;
}

.selectBox {
    height: 5vh;
    width: 200px;
    padding: 5px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-height: 30px;
}


.attributesContainer {
    width: 100%;
    margin-top: 20px;
}

.attributeDisplay {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    width: calc(100%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    text-align: center;
    height:fit-content;
}

.attributeDisplay label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.attributeDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.attributeDiv textarea {
    padding: 10px;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    flex: 1;
    min-width: 0;
    box-sizing: border-box;
    height: 100px;
}

.attributeDiv input[type="checkbox"] {
    margin-right: 1%;
}

.utilsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

p {
    text-align: center;
    margin-top: 20px;
    font-style: italic;
}