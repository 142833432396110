.dashboardTitle
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Times New Roman', Times, serif;
}

.inputDatesDiv
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inputDatesDiv h4 {
    text-align: center;
    padding-top: 5px;
    font-family: Arial, sans-serif;
}

.backButton
{
    margin: 10px;
    margin-right: 25px;
    cursor: pointer;
}

.tableContainer{
    width:20%;
}
.ag-theme-material {
    --ag-header-background-color: rgb(204, 204, 204);
}

/* Custom date input styling */
input[type="date"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 1px solid #ccc;
    padding: 5px;
    margin: 20px;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    font-family: Arial, sans-serif;
}

input[type="date"]:focus {
    outline: none;
    border-color: #0066cc;
}

/* Optional: style the placeholder (this won't work in all browsers) */
input[type="date"]::-webkit-input-placeholder {
    color: #ccc;
}

input[type="date"]::-moz-placeholder {
    color: #ccc;
}

input[type="date"]::-ms-input-placeholder {
    color: #ccc;
}

input[type="date"]::-o-placeholder {
    color: #ccc;
}