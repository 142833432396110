
  body{
	margin: 0;
  background-color: rgb(248, 248, 248);
  }

  .myborder {
    border-radius: 1% 1% 1% 1%,
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }


.orangeButton {
    padding: 10px 20px;
    background-color: #ff8800;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    margin: 3px;
}

.redButton {
    padding: 5px 10px;
    background-color: #ff4d52;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    margin: 3px;
}
.blueButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    
    margin: 3px;
}

.greyButton {
  padding: 10px 20px;
  background-color: #adadad;
  color: white;
  border: none;
  border-radius: 5px;
  cursor:not-allowed;
  transition: background-color 0.3s;

  margin: 3px;
}

.orangeButton:hover {
    background-color: #c77619;
}

.redButton:hover {
    background-color: #b82429;
}

.blueButton:hover {
    background-color: #0056b3;
}